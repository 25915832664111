import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCourseStats(ctx, queryParams) {
      console.log("courses Fetching");
      return new Promise((resolve, reject) => {
        axios
          .get('/lms/admin/course/coursescount', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSubscribersCount(ctx, queryParams) {
        console.log("courses Fetching");
        return new Promise((resolve, reject) => {
          axios
            .get('/lms/admin/course/getSubscribersCount', { params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      fetchExamStats(ctx, queryParams) {
        console.log("courses Fetching");
        return new Promise((resolve, reject) => {
          axios
            .get('/lms/admin/course/getactiveexamscount', { params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      fetchCertStats(ctx, queryParams) {
        console.log("courses Fetching");
        return new Promise((resolve, reject) => {
          axios
            .get('/lms/admin/course/getcertificatescount', { params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },

     
    
  },
}
